import "./App.css";
import logo from "./Assets/Logos/logo-dark.svg";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="" />
        <h1 className="mt-4">This is my App</h1>
        มาเขียน code กันเถอะ
      </header>
    </div>
  );
}

export default App;
